import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import background from './proyectos/proyectos.png';
import limon from './proyectos/limon.png';
import naranja from './proyectos/naranja.png';
import mandarina from './proyectos/mandarina.png';
import eraLogo from './era-logo.svg';
import { Col, Container, Row } from "reactstrap";
import ReactPlayer from "react-player";

const NavegaionFrutas: React.FC = () => {

    const [firstInteraction, setFirstInteraction] = useState<boolean>(false);
    const [contentActive, setContentActive] = useState<boolean>(false);

    const history = useHistory();

    useEffect(()=>{
        
    },[])

    const goHome = () => {
        if (contentActive) {
            setContentActive(false);
            history.push(`/proyectos`);
        }
        if (firstInteraction) {
            setFirstInteraction(false);
        }

    }


    const fruitOnMouseOver = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        //(e.target as HTMLImageElement).style.opacity = "1";
    }

    const fruitOnMouseOut = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        //(e.target as HTMLImageElement).style.opacity = "0.7";
    }

    const fruitOnClick = (fruitName: string) => (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        if (!contentActive) {
            setContentActive(true);
        }
        if (!firstInteraction) {
            setFirstInteraction(true);
        }
        history.push(`/proyectos/${fruitName}`)
    }


    const limonOnClick = fruitOnClick("limon");
    const naranjaOnClick = fruitOnClick("naranja");
    const mandarinaOnClick = fruitOnClick("mandarina");



    return <>
        <div id="background2" ></div>

        <div className={`init-message-proyects ${firstInteraction ? "hidden" : ""}`} >Interactua con las frutas.</div>

        <img className={`era-logo-proyects ${contentActive ? "era-logo-content-proyects" : "era-logo-init-proyects"} `} src={eraLogo} onClick={goHome}></img>

        <img onMouseEnter={fruitOnMouseOver} onMouseLeave={fruitOnMouseOut} className={`fruta ${contentActive ? "limon-content" : "limon-init"} `} src={limon} onClick={limonOnClick}></img>
        <img onMouseEnter={fruitOnMouseOver} onMouseLeave={fruitOnMouseOut} className={`fruta ${contentActive ? "naranja-content" : "naranja-init"} `} src={naranja} onClick={naranjaOnClick}></img>
        <img onMouseEnter={fruitOnMouseOver} onMouseLeave={fruitOnMouseOut} className={`fruta ${contentActive ? "mandarina-content" : "mandarina-init"} `} src={mandarina} onClick={mandarinaOnClick}></img>




        <div className={`content-frutas ${contentActive ? "content-active" : "content-inactive"} `}>

            <Switch>
                <Route path="/proyectos/limon">
                    <Container>

                    <h1>Servicios</h1>

                    <h3>Experiencias</h3>
                    <p>En todas incorporamos elementos virtuales a la experiencia real.
                    Experiencia musical: Principalmente usamos instrumentación nativa, que 
                    puede ser jugada de manera intuitiva estimulando la participación de los invitados. 
                    Nos encargamos de alcanzar la integración al unísono musical.</p>

                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=MLEZuvPsVek"
                        />
                    </div>
       
                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=B94JErlKiqw"
                        />
                    </div>

                    <h3>Experiencia Escénica</h3>

                    <p>
                    Haciendo uso de los ejercicios de las artes escénicas generamos espacios de comunicación efectiva. 
                    Hemos realizado este modelo en colegios, empresas y espacios públicos, teniendo muy buena acogida y recordación.

                    </p>


                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=Wa4QyEAhGVw"
                        />
                    </div>


                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=LRqvEHWU2gc"
                        />
                    </div>

                    <h3>Experiencia Holográfica</h3>
                    <p> Transformamos el espacio con visores holográficos y realidad aumentada que hace una composición del futuro que es esta ERA!</p>
                    
                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=bYlEDgbK-Bg&t=8s"
                        />
                    </div>
                    

                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=PAPl5RDn4Yc"
                        />
                    </div>
                    

                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=9EAOGd1cyQs"
                        />
                    </div>

                    </Container>

                </Route>
                <Route path="/proyectos/naranja">
                    <Container>

                            <h1>Productos</h1>

                            <h3>Ayai</h3>

                            <p>Dimensión animal, Juego interactivo, educativo y con realidad aumentada sobre animales asombrosos, 
                            es un aprendizaje inolvidable. </p>

                            <p>Sorprendente</p>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                                        url="https://www.youtube.com/watch?v=6WJWMV5XBiY&t=15s"
                                    />
                                </div>

                            <p>Abriendo la dimensión animal en cualquier parte</p>
                            <div className="embed-responsive embed-responsive-16by9">
                                    <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                                        url="https://www.youtube.com/watch?v=Cg12f3xT7Ss"
                                    />
                             </div>

                             <p>Juego de interacción para aprender sobre animales diseñado para la infancia.</p>

                             <div className="embed-responsive embed-responsive-16by9">
                                    <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                                        url="https://www.youtube.com/watch?v=lutSVucQZQw"
                                    />
                             </div>
                             <div className="embed-responsive embed-responsive-16by9">
                                    <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                                        url="https://www.youtube.com/watch?v=rJdUF1Z_Tyo"
                                    />
                             </div>
                             <div className="embed-responsive embed-responsive-16by9">
                                    <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                                        url="https://www.youtube.com/watch?v=9xHdW0AkhgM"
                                    />
                             </div>

                             <h3>Eraentusmanos</h3>

                             <p onClick={()=>history.push('/')} style={{cursor:'pointer', textDecoration: 'underline'}}> Cápsula de relajación virtual, una alternativa sencilla y efectiva para lograr tranquilidad. </p>


                    </Container>
                </Route>

                <Route path="/proyectos/mandarina">
                    <div >
                        <Container>
                        <h1>Desarrollo tecnológico. </h1>
                        <p> ¿Estás buscando una nueva forma de interactuar con tu  audiencia?  Sabemos cómo hacerlo. 
                            La realidad aumentada te permite ahorrar tiempo, comunicar de manera contundente y  favorecer el 
                            aprendizaje de manera divertida. </p>
                        <p>Nosotros podemos realizar una aplicaciones basadas en marcadores,  en este tipo de  aplicaciones, la función 
                            principal juega con marcadores situados en el mundo real. Varias  tarjetas, códigos QR o iconos pueden 
                            convertirse en desencadenantes de la integración  entre el mundo virtual y el mundo real, nuestro desarrollo 
                            incluye escribir la arquitectura  del código principal y crear el diseño de la aplicación. Ambos procesos 
                            son cuestiones  estrechamente relacionadas e interdependientes y contienen: </p> 

                        <h4>Infraestructura</h4>

                        <ul>
                            <li>La integración de las API de la 3ª parte. </li>
                            <li>Acceso a datos empresariales. </li>
                            <li>Configuración y controles básicos. </li>
                            <li>Almacenamiento de datos. </li>
                            <li>Cifrado </li>
                            <li>Escalabilidad  </li>
                        </ul>

                        
                        <h4>Diseño</h4>

                        <ul>
                            <li>La integración de las API de la 3ª parte. </li>
                            <li>Creación de estructura metálica. </li>
                            <li>Modelos gráficos 3D.</li>
                            <li>Diseño UI / UX. </li>
                            <li>Diseño visual. </li>
                            <li>Animación. </li>
                        </ul>

                        <h3>Nuestros proyectos</h3>

                        <div className="embed-responsive embed-responsive-16by9">
                                    <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                                        url="https://www.youtube.com/watch?v=iCM_M1051vg"
                                    />
                             </div>

                             <div className="embed-responsive embed-responsive-16by9">
                                    <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                                        url="https://www.youtube.com/watch?v=Pn2_9lRWOhI&t=49s"
                                    />
                             </div>

                             <p>Contamos con un equipo de aliados desarrolladores con quienes podemos crear con versatilidad y 
                                 desde múltiples plataformas. </p>
                        </Container>

                    </div>
                </Route>


            </Switch>


        </div>
    </>
}

export default NavegaionFrutas;