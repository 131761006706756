import React from "react";
// interface Props {
//     className:string
//     pulgarOnMouseOver: ((event: React.MouseEvent<SVGPathElement, MouseEvent>) => void) | undefined
//     pulgarOnMouseOut: ((event: React.MouseEvent<SVGPathElement, MouseEvent>) => void) | undefined
//     pulgarOnClick: ((event: React.MouseEvent<SVGPathElement, MouseEvent>) => void) | undefined
// }
const Mano = ({ className, fingerOnMouseOver, fingerOnMouseOut, pulgarOnClick, anularOnClick, indiceOnClick, corazonOnClick, meniqueOnClick, plamaOnClick, highlightColor="#ebba00", baseOpacity="0.1" }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1080"
      height="1078"
      version="1.1"
      viewBox="0 0 1080 1078"
    >
      <defs>
        <linearGradient id="linearGradient943">
          <stop offset="0" stopColor={highlightColor}stopOpacity="1"></stop>
          <stop offset="1" stopColor={highlightColor}stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="linearGradient925">
          <stop offset="0" stopColor={highlightColor}stopOpacity="1"></stop>
          <stop offset="1" stopColor={highlightColor}stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="linearGradient889">
          <stop offset="0" stopColor={highlightColor}stopOpacity="1"></stop>
          <stop offset="1" stopColor={highlightColor}stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="linearGradient879">
          <stop offset="0" stopColor={highlightColor}stopOpacity="1"></stop>
          <stop offset="1" stopColor={highlightColor}stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="linearGradient869">
          <stop offset="0" stopColor={highlightColor}stopOpacity="1"></stop>
          <stop offset="1" stopColor={highlightColor}stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="linearGradient859">
          <stop offset="0" stopColor={highlightColor}stopOpacity="1"></stop>
          <stop offset="1" stopColor={highlightColor}stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient861"
          x1="85.677"
          x2="378.464"
          y1="689.723"
          y2="689.723"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient859"
        ></linearGradient>
        <linearGradient
          id="linearGradient871"
          x1="341.866"
          x2="459.803"
          y1="118.94"
          y2="565.624"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient869"
        ></linearGradient>
        <linearGradient
          id="linearGradient881"
          x1="572.296"
          x2="558.922"
          y1="101.996"
          y2="485.883"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient879"
        ></linearGradient>
        <linearGradient
          id="linearGradient891"
          x1="711.465"
          x2="643.114"
          y1="215.801"
          y2="511.411"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient889"
        ></linearGradient>
        <linearGradient
          id="linearGradient927"
          x1="859.866"
          x2="758.404"
          y1="376.135"
          y2="565.488"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient925"
        ></linearGradient>
        <radialGradient
          id="radialGradient945"
          cx="572.293"
          cy="740.914"
          r="230.077"
          fx="572.293"
          fy="740.914"
          gradientTransform="matrix(1 0 0 1.31116 0 -230.54)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#linearGradient943"
        ></radialGradient>
      </defs>
      <g>
        <image
          width="1080"
          height="1078"
          preserveAspectRatio="none"
          xlinkHref="/mano-med.png"
        ></image>
        <path
          cursor="pointer"
          onMouseOver={fingerOnMouseOver}
          onMouseOut={fingerOnMouseOut}
          onClick={pulgarOnClick}
          fill="url(#linearGradient861)"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          d="M377.8 942.682c-41.177-25.627-57.411-65.387-93.542-98.082-21.78-25.367-42.79-56.897-63.728-89.001l-26.181-75.855c-10.968-24.36-29.756-43.591-50.16-75.323-10.045-41.82-57.12-103.767-54.06-130.576-1.338-24.282 31.094-35.829 39.208-33.106 11.349-1.196 46.122 4.233 55.93 16.071 21.645 12.782 34.796 29.339 49.041 45.409 8.273 12.81 19.455 23.605 25.64 49.142 12.14.052 22.16 40.862 44.014 73.332 2.228 13.358 20.566 17.504 38.254 25.98z"
          opacity={baseOpacity}
        ></path>
        <path
          cursor="pointer"
          onMouseOver={fingerOnMouseOver}
          onMouseOut={fingerOnMouseOut}
          onClick={indiceOnClick}
          fill="url(#linearGradient871)"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          d="M368.608 486.768c-10.454-52.712-26.822-93.416-30.824-138.067-15.248-39.208-16.31-79.462-23.118-121.371-12.446-42.305-16.5-79.844-12.844-120.087-1.397-19.009 49.18-58.172 78.987-6.743 14.138 27.375 17.324 54.594 23.119 105.96 8.297 37.78 22.65 75.562 29.54 113.343 10.309 32.75 25.166 65.502 28.577 98.253 2.907 6.638 3.515 12.923 17.017 21.191z"
          opacity={baseOpacity}
        ></path>
        <path
          cursor="pointer"
          onMouseOver={fingerOnMouseOver}
          onMouseOut={fingerOnMouseOut}
          onClick={corazonOnClick}
          fill="url(#linearGradient881)"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          d="M498.327 435.715c-2.548-55.334-1.63-110.668.643-166.002 3.117-43.437 11.492-80.566 17.338-120.729 6.278-41.069 7.377-98.249 18.89-123.031 16.979-31.148 64.142-33.66 86.106 4.23 12.115 41.132-11.43 111.634-13.165 135.498.766 45.815-1.385 90.536-5.458 134.536-1.335 24.143 2.876 82.28-1.285 128.756.11 4.067.26 8.134.964 12.2z"
          opacity={baseOpacity}
        ></path>
        <path
          cursor="pointer"
          onMouseOver={fingerOnMouseOver}
          onMouseOut={fingerOnMouseOut}
          onClick={anularOnClick}
          fill="url(#linearGradient891)"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          d="M614.832 447.729c-.31-37.538 7.686-75.076 14.985-112.614 8.89-33.809 18.994-68.225 29.062-102.623 3.774-13.325 4.916-29.835 8.023-41.903 11.213-43.57 12.835-70.864 21.492-76.613 23.891-25.903 56.996-18.699 74.47 13.168 11.722 13.785-19.207 106.461-22.704 127.144-3.501 31.862-10.338 61.68-16.801 93.542-6.55 23.2-10.553 49.681-12.26 74.697-2.081 23.99-7.228 47.982-4.995 71.973z"
          opacity={baseOpacity}
        ></path>
        <path
          cursor="pointer"
          onMouseOver={fingerOnMouseOver}
          onMouseOut={fingerOnMouseOut}
          onClick={meniqueOnClick}
          fill="url(#linearGradient927)"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          d="M728.354 524.923c12.959-22.875 24.72-43.058 40.868-73.107 4.187-8.57 21.539-33.862 39.96-64.935 12.92-23.763 24.843-55.95 45.591-81.176 11.114-10.315 24.63-18.864 47.042-6.584 23.417 17.41 15.693 43.641 14.076 49.95-11.105 29.428-24.904 56.944-37.689 75.5-10.603 32.392-22.163 50.049-40.147 83.634-14.209 31.526-24.196 58.23-35.685 86.648z"
          opacity={baseOpacity}
        ></path>
        <path
          cursor="pointer"
          onMouseOver={fingerOnMouseOver}
          onMouseOut={fingerOnMouseOut}
          onClick={plamaOnClick}
          fill="url(#radialGradient945)"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          d="M355.361 926.235c9.488-89.543 12.953-180.089-13.145-275.562 4.584-28.86 18.534-62.403 27.877-93.643 2.88-23.42.584-46.841-.577-70.262 42.74-6.264 84.325-23.543 115.968-46.878 39.226 1.901 79.217 8.008 116.876 1.284 28.479 26.59 68.36 36.71 103.744 53.326 1.456 10.803 13.807 20.396 22.25 30.423 21.626 29.064 44.751 55.294 74.016 69.93-9.023 71.442-28.673 143.206-45.065 214.65l-55.288 142.261c-15.465 14.052-18.825 23.92-18.475 35.419l-3.321 55.398-297.88-6.357c2.48-29.036 4.531-56.037 3.632-69.021-8.693-26.282-17.767-32.322-30.612-40.968z"
          opacity={baseOpacity}
        ></path>
      </g>
    </svg>


  );
}

export default Mano;
