import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { Container } from "reactstrap";


const Contacto : React.FC = ()=>{
    return <>
    <div id="background2" />

    <FontAwesomeIcon className="contacto facebook-icon" onClick={()=> window.open("https://www.facebook.com/ExperienciaRealidadAumentada")} icon={faFacebook}/>

    <FontAwesomeIcon className="contacto instagram-icon" onClick={()=> window.open("https://www.instagram.com/experienciarealidadaumentada/")} icon={faInstagram}/>

    <div className="contacto whatsapp-icon" >

        <FontAwesomeIcon  onClick={()=> window.open("https://wa.me/573015700490")} icon={faWhatsapp}/>
        <h3 className="number">+573015700490</h3>


    </div>
    
    
    </>
}

export default Contacto;