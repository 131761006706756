import React, { useState } from 'react';
import background from './background.png';

import eraLogo from './era-logo.svg';
import ReactPlayer from "react-player"

import './App.css';
import { Collapse, Container, Jumbotron, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, } from 'reactstrap';
import Mano from './mano-svg';
import { Document, Page, pdfjs } from 'react-pdf';

import {
  Switch,
  Route,
  useHistory,
  Redirect,
  Link
} from "react-router-dom";


import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import NavegacionMano from './NavegacionMano';
import NavegaionFrutas from './NavegacionFrutas';
import Contacto from './Contacto';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const App: React.FC = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);



  return (
    < >

      <Container fluid={true} style={{ height: "100%", padding: 0 }}>

        <Jumbotron style={{ height: "100%" }}>

          <switch>
            <Route path='/mano'>
              <NavegacionMano />
            </Route>
            <Route path='/proyectos'>
              <NavegaionFrutas />
            </Route>

            <Route path='/contacto'>
              <Contacto />
            </Route>

            <Route exact path="/">
              <Redirect to={`/mano`} />
            </Route>
          </switch>

        

        </Jumbotron>

      </Container>

      <footer className="footer fixed-bottom">

          {/* <span className="text-muted">Experiencia de Realidad Aumentada</span> */}

          <Navbar color="light" light expand="md">
        <NavbarBrand href="/mano">Experiencia  Realidad Aumentada</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="/mano/" className="nav-link" activeClassName="active">Inicio</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/proyectos" className="nav-link" activeClassName="active">Proyectos</NavLink>
            </NavItem>

            <NavItem>
              <NavLink href="/contacto" className="nav-link" activeClassName="active">Contacto</NavLink>
            </NavItem>

          </Nav>

        </Collapse>
      </Navbar>



      </footer>

    </>
  );
}

export default App;
