import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Route, Switch, useHistory } from "react-router-dom";
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Document, Page, pdfjs } from 'react-pdf';
import background from './background.png';

import eraLogo from './era-logo.svg';
import Mano from "./mano-svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const NavegacionMano: React.FC = () => {

    const [firstInteraction, setFirstInteraction] = useState<boolean>(false);
    const [contentActive, setContentActive] = useState<boolean>(false);

    const history = useHistory();

    const goHome = () => {
        if (contentActive) {
            setContentActive(false);
            history.push(`/mano`);
        }
        if (firstInteraction) {
            setFirstInteraction(false);
        }

    }


    const fingerOnMouseOver = (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
        (e.target as SVGPathElement).style.opacity = "0.8";
    }

    const fingerOnMouseOut = (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
        (e.target as SVGPathElement).style.opacity = "0.1";
    }

    const fingerOnClick = (fingerName: string) => (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
        if (!contentActive) {
            setContentActive(true);
        }
        if (!firstInteraction) {
            setFirstInteraction(true);
        }
        history.push(`/mano/${fingerName}`)
    }


    const pulgarOnClick = fingerOnClick("pulgar");
    const indiceOnClick = fingerOnClick("indice");
    const corazonOnClick = fingerOnClick("corazon");
    const anularOnClick = fingerOnClick("anular");
    const meniqueOnClick = fingerOnClick("menique");
    const palmaOnClick = fingerOnClick("palma");


    return <>


        <div id="background" style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}></div>

        <div className={`init-message ${firstInteraction ? "hidden" : ""}`} >Interactua con la mano.</div>

        <img className={`era-logo ${contentActive ? "era-logo-content" : "era-logo-intro"} `} src={eraLogo} onClick={goHome}></img>


        <Mano className={`mano ${contentActive ? "mano-left" : "mano-center"} `}
            fingerOnMouseOut={fingerOnMouseOut}
            fingerOnMouseOver={fingerOnMouseOver}
            pulgarOnClick={pulgarOnClick}
            indiceOnClick={indiceOnClick}
            corazonOnClick={corazonOnClick}
            anularOnClick={anularOnClick}
            meniqueOnClick={meniqueOnClick}
            plamaOnClick={palmaOnClick}
            baseOpacity="0"
        />

        <div className={`content ${contentActive ? "content-active" : "content-inactive"} `}>

            <Switch>
                <Route path="/mano/pulgar">
                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=fY3E7RJXs3w"
                        />
                    </div>

                </Route>
                <Route path="/mano/indice">
                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=xCKvc5Ejqno"
                        />
                    </div>
                </Route>

                <Route path="/mano/corazon">
                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=g3CpMwJ_2sE"
                        />
                    </div>
                </Route>

                <Route path="/mano/anular">
                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=FALVkJirdl8"
                        />
                    </div>
                </Route>

                <Route path="/mano/menique">
                    <div className="embed-responsive embed-responsive-16by9">
                        <ReactPlayer className="embed-responsive-item" width="100%" height="100%" controls={true}
                            url="https://www.youtube.com/watch?v=UX_kg-WY8oo"
                        />
                    </div>
                </Route>
                <Route path="/mano/palma">


                    <AutoSizer disableHeight>
                        {({ width }: { width: number }) => {
                            return (
                                <Document file="/doc.pdf" renderMode="canvas">
                                    {[...Array(4).keys()].map((i) => (
                                        <Page key={i} pageNumber={i + 1} width={width - 10} />
                                    ))}
                                </Document>
                            );
                        }}
                    </AutoSizer>
                </Route>
            </Switch>


        </div>
    </>

}

export default NavegacionMano;

